@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/themes/prism-okaidia";

body, html, div, h1, h2, h3, h4, h5, h6, li, a, ul
{
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0px;
    padding: 0px;
}

$primary-color: #0077ae;
$background-color: black;

html
{
    overflow-y: scroll;
}

body
{
    min-width: 1050px;
    background-color: white;
}

a{
    color: $primary-color;
    text-decoration: none;
}

p
{
    line-height: 30px;
}

.align-with-nav
{
    width: 1000px !important;
    margin-left: auto;
    margin-right: auto;
}

.button
{
    display: inline-block;
    width: auto;

    background-color: $primary-color;
    padding: 20px;
    border-radius: 1000px;

    color: #fefefe;
    cursor: pointer;
}

.small-button
{
    display: inline-block;
    height: 16px;
    padding-top: 4px;
}

.button:hover
{
    background-color: darken($color: $primary-color, $amount: 5%);
    color: white;
}

.trendy-gradient-bar-that-everyone-is-in-love-with
{
    background: linear-gradient(to left top, darken($primary-color, $amount: 10%), $primary-color, lighten($primary-color, $amount: 10%));
    height: 60px;
}

.trendy-gradient-bar-that-everyone-is-in-love-with-dt
{
    background: linear-gradient(to top left, lighten($primary-color, $amount: 5%), $primary-color, darken($primary-color, $amount: 10%));
    height: 60px;
}

.title-heading
{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30pt;
}

.featured
{
    background-color: $background-color;
    height: 600px;

    color: #fefefe;

    h1
    {
        padding-top: 10px;
        padding-bottom: 10px;

        margin-top: 40px;
        margin-bottom: 40px;

        border-bottom: 3px solid white;
    }
}

.featured-slide
{
    background-position:  center;
    height: 400px;

    padding: 20px;

    p
    {   
        width: 980px;
        height: 50px;

        background: rgba(0, 0, 0, 0.2);
        padding: 10px;
        padding-top: 25px;
        margin: 0px;

        display: block;
        position: relative;
        left: -20px;
        top: 310px;

        .button
        {
            height: 20px;
            float: right;

            margin-top: -10px;
            padding-top: 10px;
        }
    }
}


.light-nav
{
    display: inline-block;
    list-style: none;
    margin-bottom: 8px;

    li{
        display: inline-block;
        margin-right: 12px;

        padding-bottom: 8px;
        text-align: center;

        min-width: 100px;
        font-size: 15pt;
        border-bottom: 2px solid black; 
    }

    li:hover
    {
        color: $primary-color;
        border-bottom: 2px solid $primary-color; 
    }
}

.hero
{
    width: 100%;
    min-height: 700px;
    background-color: #000000;

    color: #fefefe;
    h1
    {
        font-size: 62pt;
    }

    p 
    {
        line-height: 30px;
        text-align: justify;
    }
    .bottomize
    {
        position: relative;
        top: 500px;
        margin-bottom: 0px;
    }
}

.hero:nth-of-type(even)
{
    background-color: #fefefe;
    color: black;
}

.underlined-heading
{
    color: white;
    border-bottom: white 3px solid;
    margin: 40px 0;
    margin-top: 0px;
    padding: 10px 0;
}